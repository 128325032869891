import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Link } from 'gatsby-plugin-intl';
import propTypes from 'prop-types';
import * as styles from './MarkdownContent.module.css';

const MarkdownContent = ({ markdown, centered }) => {
  return (
    <div
      className={`container ${styles.markdownContainer} ${
        centered ? styles.centered : ''
      }`}
    >
      <ReactMarkdown
        linkTarget="_blank"
        components={{
          a: ({ href, title, children }) => {
            const char = href.charAt(0);
            if (char === '.' || (char === '/' && !href.endsWith('.pdf'))) {
              return (
                <Link to={href} title={title}>
                  {children}
                </Link>
              );
            } else {
              return (
                <a href={href} title={title}>
                  {children}
                </a>
              );
            }
          },
        }}
      >
        {typeof markdown === 'string'
          ? markdown.replace(/<br\/>/g, '\n')
          : markdown}
      </ReactMarkdown>
    </div>
  );
};

MarkdownContent.propTypes = {
  markdown: propTypes.string,
  centered: propTypes.bool,
};

export default MarkdownContent;
